import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  Button,
  notification,
  Input,
  Form,
  Select,
  DatePicker,
  Divider,
  Spin,
  Row,
  Col,
  Icon,
  Typography,
  Modal,
  InputNumber,
  Tooltip,
} from 'antd';
import { isEmpty, get, some } from 'lodash';
import { unescape as htmlUnescape } from 'html-escaper';

import ModifyDaysToFollowUpButton from './modifyDaysToFollowUpButton/ModifyDaysToFollowUpButton';
import ConditionalDueDate from './conditionalDueDate/ConditionalDueDate';

import caseFileEndpoints from '../../../api/caseFileEndpoints/caseFileEndpoints';
import { getUserData } from '../utils/utils';
import { getAgencyId } from '../../../selectors/session';

import parseDate, {
  formatDate,
  BENCHMARK_DATE_FORMAT,
  BENCHMARK_DATE_FORMAT_HYPHEN,
} from '../../../utils/parse-date';
import { StyledModal, RemoveButton } from './AddTaskModal.styled';
import './style/AddTaskModal.css';

const FormItem = Form.Item;
const Option = Select.Option;
const TextArea = Input.TextArea;
const Paragraph = Typography.Paragraph;
const confirm = Modal.confirm;

const NOTIFICATION_ERROR = 'error';
const EDIT_PERMISSION = 'edit';

const MINIMUM_DAYS_OF_REMINDER = 1;
const MAXIMUM_DAYS_OF_REMINDER = 365;

const DISABLE_DATE_TOOLTIP_ERROR_MESSAGE =
  'This date cannot be changed since a notification has already been sent.';

const layouts = {
  assignee: {
    labelCol: {
      span: 3,
    },
    wrapperCol: {
      span: 10,
    },
  },
  dueDate: {
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 12,
    },
  },
  description: {
    labelCol: {
      span: 2,
    },
    wrapperCol: {
      span: 22,
    },
  },
  reminderDate: {
    labelCol: {
      span: 1,
    },
    wrapperCol: {
      span: 22,
    },
  },
};

class AddTaskModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fetching: false,
      assignee: [],
      description: '',
      prevDescription: undefined,
      dueDate: null,
      status: null,
      daysToFollowUp: null,
      daysToFollowUpValidationStatus: 'success',
      followUpDate: null,
      upperDayReminderLimit: MAXIMUM_DAYS_OF_REMINDER,
      loading: false,
      data: [],
      executionId: '',
      hasReminderDatePassed: false,
      hasDueDatePassed: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { visible, isNew } = nextProps;
    const { prevDescription } = prevState;
    if (visible && !isNew && prevDescription === undefined) {
      const { prefix, sectionId, rowIndex } = nextProps;
      const nextAssignee = get(nextProps, [
        'profileForm',
        'values',
        prefix,
        sectionId,
        'assignee',
        rowIndex,
      ]);

      const nextDescription = get(nextProps, [
        'profileForm',
        'values',
        prefix,
        sectionId,
        'description',
        rowIndex,
      ]);

      const nextDueDate = get(nextProps, [
        'profileForm',
        'values',
        prefix,
        sectionId,
        'dueDate',
        rowIndex,
      ]);

      const nextStatus = get(nextProps, [
        'profileForm',
        'values',
        prefix,
        sectionId,
        'status',
        rowIndex,
      ]);

      const nextFollowUpDate = get(nextProps, [
        'profileForm',
        'values',
        prefix,
        sectionId,
        'followUpDate',
        rowIndex,
      ]);

      const nextExecutionId = get(nextProps, [
        'profileForm',
        'values',
        prefix,
        sectionId,
        'executionId',
        rowIndex,
      ]);

      const formattedNextAssignee = nextAssignee.id
        ? [
            {
              key: nextAssignee.id,
              label: nextAssignee.fullName,
            },
          ]
        : [];

      let nextDaysToFollowUp = null;
      let nextHasReminderDatePassed = false;
      let nextHasDueDatePassed = false;
      let nextUpperDayReminderLimit = MAXIMUM_DAYS_OF_REMINDER;
      if (nextDueDate) {
        const today = moment();
        const transformedDueDate = moment(nextDueDate, 'YYYY-MM-DD');
        nextHasDueDatePassed = transformedDueDate.isBefore();
        const diffBetweenDueDateAndToday = transformedDueDate.diff(
          today,
          'days'
        );
        nextUpperDayReminderLimit =
          diffBetweenDueDateAndToday > 0 ? diffBetweenDueDateAndToday : null;

        if (nextFollowUpDate) {
          const transformedFollowUpDate = moment(
            nextFollowUpDate,
            'YYYY-MM-DD'
          );
          nextDaysToFollowUp = transformedDueDate.diff(
            transformedFollowUpDate,
            'days'
          );
          nextHasReminderDatePassed = transformedFollowUpDate.isBefore();
        } else {
          nextHasReminderDatePassed = nextHasDueDatePassed;
        }
      }

      return {
        assignee: formattedNextAssignee,
        dueDate: nextDueDate,
        status: nextStatus,
        description: nextDescription,
        prevDescription: nextDescription,
        followUpDate: nextFollowUpDate,
        daysToFollowUp: nextDaysToFollowUp,
        executionId: nextExecutionId,
        upperDayReminderLimit: nextUpperDayReminderLimit,
        hasReminderDatePassed: nextHasReminderDatePassed,
        hasDueDatePassed: nextHasDueDatePassed,
      };
    }
    return null;
  }

  showNotification = (type, message, description) =>
    notification[type]({ message, description });

  initData = () =>
    this.setState({
      fetching: false,
      assignee: [],
      description: '',
      prevDescription: undefined,
      dueDate: null,
      status: null,
      daysToFollowUp: null,
      daysToFollowUpValidationStatus: 'success',
      loading: false,
      followUpDate: null,
      upperDayReminderLimit: MAXIMUM_DAYS_OF_REMINDER,
      data: [],
      executionId: '',
      hasReminderDatePassed: false,
      hasDueDatePassed: false,
    });

  onModalOk = e => {
    const { assignee } = this.state;
    const { shares } = this.props;
    const selectedAssignee = assignee.length > 0 ? assignee[0] : null;

    if (selectedAssignee) {
      const assigneeData = shares.find(
        share => share.userIntegrationId === selectedAssignee.key
      );
      if (!assigneeData || assigneeData.mode !== EDIT_PERMISSION) {
        this.showAssigneeDialog(e);
      } else {
        this.saveTask(e);
      }
    } else {
      this.saveTask(e);
    }
  };

  onModalCancel = () => {
    const { onCancel } = this.props;
    onCancel && onCancel();
    this.initData();
  };

  onModalRemove = e => {
    const { onOk, isNew, rowIndex } = this.props;
    const { description } = this.state;

    this.setState({ loading: true });
    this.deleteTask()
      .then(response => {
        if (response) {
          const methodType = 'remove';
          onOk && onOk(e, { description, isNew, rowIndex, methodType });
          this.initData();
        }
      })
      .catch(err => {
        const message = err.message || 'API response is not ok';
        this.showNotification(NOTIFICATION_ERROR, 'Error', message);
      })
      .finally(() => this.setState({ loading: false }));
  };

  saveTask = e => {
    const { assignee, description, dueDate, followUpDate } = this.state;
    const { onOk, isNew, rowIndex, timezone, userId } = this.props;
    const selectedAssignee = assignee.length > 0 ? assignee[0] : null;
    const formattedDueDate = dueDate
      ? parseDate(dueDate, timezone, BENCHMARK_DATE_FORMAT_HYPHEN)
      : null;
    const payload = {
      assignee: selectedAssignee,
      dueDate: formattedDueDate,
      description,
      followUpDate,
    };

    this.setState({ loading: true });
    if (isNew) {
      this.addTaskToCasefile(payload)
        .then(response => {
          if (response) {
            const formattedResponse = this.formatResponse(
              payload,
              selectedAssignee
            );
            formattedResponse.creator = userId;
            this.setState({ editForm: null, isNew: false });
            onOk && onOk(e, formattedResponse);
            this.initData();
          } else {
            throw new Error();
          }
        })
        .catch(err => {
          const message = err.message || 'API response is not ok';
          this.showNotification(NOTIFICATION_ERROR, 'Error', message);
          this.onModalCancel();
        })
        .finally(() => this.setState({ loading: false }));
    } else {
      // TODO: Call endpoint to edit the task
      this.editTask(payload)
        .then(response => {
          if (response) {
            const formattedResponse = this.formatResponse(
              payload,
              selectedAssignee
            );
            formattedResponse.isNew = isNew;
            formattedResponse.rowIndex = rowIndex;
            this.setState({ editForm: null });
            onOk && onOk(e, formattedResponse);
            this.initData();
          } else {
            throw new Error();
          }
        })
        .catch(err => {
          const message = err.message || 'API response is not ok';
          this.showNotification(NOTIFICATION_ERROR, 'Error', message);
          this.onModalCancel();
        })
        .finally(() => this.setState({ loading: false }));
    }
  };

  showAssigneeDialog = e => {
    const { assignee } = this.state;
    confirm({
      autoFocusButton: null,
      title: (
        <Paragraph
          strong
          style={{
            color: '#121212',
            fontSize: '16px',
          }}
        >
          By assigning this task to {assignee[0].label}, you will be granting
          them edit access to this case file.
        </Paragraph>
      ),
      okText: 'Confirm',
      width: 1000,
      style: {
        top: '32px',
      },
      icon: <Icon type="info-circle" theme="twoTone" />,
      onOk: () => this.saveTask(e),
      onCancel: () => {},
    });
  };

  addTaskToCasefile = taskObject => {
    const { casefileId } = this.props;
    const payloadTask = this.formatPayload(taskObject);
    return caseFileEndpoints.addTaskToCasefile(casefileId, payloadTask);
  };

  editTask = taskObject => {
    const { status } = this.state;
    const { casefileId } = this.props;
    const payloadTask = this.formatPayload(taskObject);
    return caseFileEndpoints.updateTask(casefileId, status.id, payloadTask);
  };

  deleteTask = () => {
    const { executionId, status } = this.state;
    const { casefileId } = this.props;
    return caseFileEndpoints.deleteTask(casefileId, status?.id, executionId);
  };

  formatPayload = taskObject => {
    const { isNew } = this.props;
    const dueDate = taskObject.dueDate || null;
    const assignee = taskObject.assignee?.key || null;
    const assigneeFullName = taskObject.assignee?.label || null;
    const followUpDate = taskObject.followUpDate || null;
    return Object.assign(
      {
        description: taskObject.description,
      },
      isNew ? dueDate && { dueDate } : { dueDate },
      isNew ? assignee && { assignee } : { assignee },
      isNew ? assigneeFullName && { assigneeFullName } : { assigneeFullName },
      isNew ? dueDate && followUpDate && { followUpDate } : { followUpDate }
    );
  };

  formatResponse = (payload, selectedAssignee) => {
    return {
      ...payload,
      assignee: {
        id: selectedAssignee?.key,
        fullName: selectedAssignee?.label,
      },
    };
  };

  handleChangeDescription = e => {
    const { value } = e.target;
    this.setState({ description: value });
  };

  getValidMaximumReminderDays = numberOfDays => {
    return numberOfDays > MAXIMUM_DAYS_OF_REMINDER
      ? MAXIMUM_DAYS_OF_REMINDER
      : numberOfDays;
  };

  handleChangeDueDate = e => {
    const date = e ? e.toDate() : null;
    const remainingDays = date
      ? this.getValidMaximumReminderDays(this.calculateReminingDays(date))
      : MAXIMUM_DAYS_OF_REMINDER;

    this.setState({
      dueDate: date,
      daysToFollowUp: null,
      followUpDate: null,
      daysToFollowUpValidationStatus: 'success',
      upperDayReminderLimit: remainingDays,
    });
  };

  handleChangeAssignee = (selectedAssignee, options) => {
    if (options?.length === 0) {
      this.setState({
        assignee: [],
      });
    } else if (options?.length === 1) {
      this.setState({
        assignee: [{ key: options[0]?.key, label: selectedAssignee[0]?.label }],
      });
    } else {
      const index = options.length - 1;
      this.setState({
        assignee: [
          { key: options[index]?.key, label: selectedAssignee[index]?.label },
        ],
      });
    }
  };

  getAssignees = (filter = '') => {
    const { casefileId } = this.props;
    const pageSize = 50;
    const pageNumber = 0;
    return caseFileEndpoints.getShareUsersCasefile(casefileId, {
      pageSize,
      pageNumber,
      fullName: filter,
    });
  };

  // Fetch assignee
  fetchAssignees = (value = '') => {
    this.setState({ fetching: true });
    this.getAssignees(value).then(response => {
      const {
        content: { data: users },
      } = response;
      // to filter out current user
      const userIntegrationId = get(this.props, 'user.userIntegrationId');
      const data = users
        .filter(user => user.integrationId !== userIntegrationId)
        .map(user => getUserData(user));

      this.setState({
        data,
        fetching: false,
      });
    });
  };

  getSelectOptions = () => {
    const { data, assignee } = this.state;

    return data.map(d => {
      const exist = some(assignee, ['key', d.key]);
      return (
        <Option key={d.key} disabled={exist} value={d.label}>
          {d.label}
        </Option>
      );
    });
  };

  disabledDate(current) {
    return current && current < moment().startOf('day');
  }

  handleButtonNumberChange = operation => {
    const { daysToFollowUp } = this.state;

    let newNumber = daysToFollowUp || 0;
    if (operation === 'add') {
      newNumber = newNumber + 1;
    } else if (operation === 'subtract') {
      newNumber = newNumber - 1;
    }
    this.checkIfDayNumberIsValid(newNumber);
  };

  renderReminderDate() {
    const { timezone } = this.props;
    const {
      daysToFollowUp,
      dueDate,
      hasReminderDatePassed,
      followUpDate,
    } = this.state;

    if (hasReminderDatePassed) {
      return followUpDate
        ? parseDate(followUpDate, timezone, BENCHMARK_DATE_FORMAT)
        : '';
    }

    if (!dueDate) {
      return;
    }

    if (dueDate && !daysToFollowUp) {
      return '';
    }

    if (daysToFollowUp % 1 != 0) {
      return 'Number must be an integer';
    }

    if (
      daysToFollowUp < MINIMUM_DAYS_OF_REMINDER ||
      daysToFollowUp > MAXIMUM_DAYS_OF_REMINDER
    ) {
      return `Numbers must be between ${MINIMUM_DAYS_OF_REMINDER} and ${MAXIMUM_DAYS_OF_REMINDER}`;
    }

    const today = new Date();
    const transformedDueDate = new Date(dueDate);
    const calculatedDate = new Date(
      transformedDueDate.setDate(transformedDueDate.getDate() - daysToFollowUp)
    );

    if (calculatedDate.getTime() < today.getTime()) {
      return 'The reminder date cannot be set before today';
    }

    return parseDate(calculatedDate, timezone, BENCHMARK_DATE_FORMAT);
  }

  checkIfDayNumberIsValid(input) {
    // Prevents the function from clearing up `daysToFollowUp` when you reopen the same modal
    if (!input) return;

    const { dueDate } = this.state;
    const { timezone } = this.props;

    let newStatus = '';
    let newFollowUpDate = null;
    if (!dueDate) {
      newStatus = 'success';
    } else if (dueDate && !input) {
      newStatus = 'success';
    } else if (input % 1 != 0) {
      newStatus = 'error';
    } else if (
      input < MINIMUM_DAYS_OF_REMINDER ||
      input > MAXIMUM_DAYS_OF_REMINDER
    ) {
      newStatus = 'error';
    } else {
      const today = new Date();
      const transformedDueDate = new Date(dueDate);
      const calculatedDate = new Date(
        transformedDueDate.setDate(transformedDueDate.getDate() - input)
      );

      if (calculatedDate.getTime() < today.getTime()) {
        newStatus = 'error';
      } else {
        newStatus = 'success';
        newFollowUpDate = parseDate(
          calculatedDate,
          timezone,
          BENCHMARK_DATE_FORMAT_HYPHEN
        );
      }
    }

    this.setState({
      daysToFollowUp: input,
      daysToFollowUpValidationStatus: newStatus,
      followUpDate: newFollowUpDate,
    });
  }

  calculateReminingDays(baseDueDate) {
    const today = moment();
    const transformedDueDate = moment(baseDueDate, 'YYYY-MM-DD');
    return transformedDueDate.diff(today, 'days');
  }

  handleToolTipMessage() {
    const { dueDate, hasReminderDatePassed } = this.state;
    if (!dueDate) {
      return 'Please enter a Due Date';
    }

    if (hasReminderDatePassed) {
      return DISABLE_DATE_TOOLTIP_ERROR_MESSAGE;
    }

    let message = '';
    const differenceInDays = this.getValidMaximumReminderDays(
      this.calculateReminingDays(dueDate)
    );

    if (differenceInDays <= 0) {
      message = 'There are no remaining days available';
    } else {
      message = `Remaining Days: ${differenceInDays}`;
    }

    return message;
  }

  isRemoveDisabled() {
    const {
      userId,
      profileForm,
      prefix,
      sectionId,
      rowIndex,
      isNew,
      readOnly,
      isCasefileClosed,
      isContributor,
    } = this.props;
    const { loading } = this.state;
    const creator = get(profileForm, [
      'values',
      prefix,
      sectionId,
      'creator',
      rowIndex,
    ]);
    const isOwner = userId === creator;
    return (
      isContributor ||
      isCasefileClosed ||
      isNew ||
      (readOnly && !isOwner) ||
      loading
    );
  }

  isSaveDisabled() {
    const { readOnly, isCasefileClosed, isContributor } = this.props;
    const {
      description = '',
      loading,
      daysToFollowUpValidationStatus,
    } = this.state;
    return (
      isContributor ||
      isCasefileClosed ||
      isEmpty(description.trim()) ||
      loading ||
      daysToFollowUpValidationStatus !== 'success' ||
      readOnly
    );
  }

  isReminderDateDisabled() {
    const { readOnly, isCasefileClosed } = this.props;
    const {
      dueDate,
      daysToFollowUp,
      hasReminderDatePassed,
      upperDayReminderLimit,
    } = this.state;
    const subtractDisabled =
      isCasefileClosed ||
      !dueDate ||
      daysToFollowUp <= MINIMUM_DAYS_OF_REMINDER ||
      hasReminderDatePassed ||
      readOnly;
    const addDisabled =
      isCasefileClosed ||
      !dueDate ||
      daysToFollowUp >= upperDayReminderLimit ||
      hasReminderDatePassed ||
      readOnly;
    const reminderDateDisabled =
      isCasefileClosed || !dueDate || hasReminderDatePassed || readOnly;
    return { subtractDisabled, addDisabled, reminderDateDisabled };
  }

  handleReminderDateOnBlur(e) {
    const { daysToFollowUpValidationStatus } = this.state;

    // IA-323: Clears the error status when the field loses focus
    if (daysToFollowUpValidationStatus === 'error') {
      this.setState({
        daysToFollowUp: null,
        daysToFollowUpValidationStatus: 'success',
      });
    }
  }

  render() {
    const { title, visible, readOnly } = this.props;
    const {
      description = '',
      assignee,
      fetching,
      dueDate,
      daysToFollowUp,
      daysToFollowUpValidationStatus,
      upperDayReminderLimit,
      hasDueDatePassed,
    } = this.state;
    const editable = !readOnly;
    const formattedDate = dueDate ? formatDate(dueDate) : null;
    const selectOptions = this.getSelectOptions();
    const disableSave = this.isSaveDisabled();
    const disableRemove = this.isRemoveDisabled();
    const {
      subtractDisabled,
      addDisabled,
      reminderDateDisabled,
    } = this.isReminderDateDisabled();
    const formattedDaysToFollowUp = daysToFollowUp > 0 ? daysToFollowUp : null;

    return (
      <StyledModal
        title={title}
        width={1000}
        className="add-task-modal"
        centered
        visible={visible}
        onOk={this.onModalOk}
        onCancel={this.onModalCancel}
        footer={[
          <div key="add-task-modal-actions-remove">
            <RemoveButton
              type="danger"
              key="Remove"
              onClick={this.onModalRemove}
              disabled={disableRemove}
            >
              Remove Task
            </RemoveButton>
          </div>,
          <div key="add-task-modal-actions">
            <Button key="cancel" onClick={this.onModalCancel}>
              Cancel
            </Button>
            <Button
              key="Save"
              type="primary"
              onClick={this.onModalOk}
              disabled={disableSave}
            >
              Save
            </Button>
          </div>,
        ]}
      >
        <Form>
          <Row>
            <Col span={16}>
              <FormItem {...layouts.assignee} label="Assignee" colon={false}>
                <Select
                  mode="multiple"
                  labelInValue={true}
                  value={assignee}
                  placeholder="Please select"
                  notFoundContent={
                    fetching ? <Spin size="small" /> : 'No assignees found'
                  }
                  filterOption={false}
                  onFocus={this.fetchAssignees}
                  onSearch={this.fetchAssignees}
                  onChange={this.handleChangeAssignee}
                  style={{ width: '100%' }}
                  disabled={!editable}
                >
                  {selectOptions}
                </Select>
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem {...layouts.dueDate} label="Due Date" colon={false}>
                <ConditionalDueDate
                  hasDueDatePassed={hasDueDatePassed}
                  message={DISABLE_DATE_TOOLTIP_ERROR_MESSAGE}
                >
                  <DatePicker
                    disabledDate={this.disabledDate}
                    format={BENCHMARK_DATE_FORMAT}
                    onChange={this.handleChangeDueDate}
                    value={formattedDate}
                    disabled={!editable || hasDueDatePassed}
                  />
                </ConditionalDueDate>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col
              span={24}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '25px',
              }}
            >
              <div className="reminder-day-container">
                <div className="main-field-container">
                  <span>Remind the asignee </span>
                  <ModifyDaysToFollowUpButton
                    disabled={subtractDisabled}
                    onClick={this.handleButtonNumberChange}
                    operator="subtract"
                  />
                  <FormItem
                    {...layouts.reminderDate}
                    colon={false}
                    style={{ marginBottom: '0' }}
                    validateStatus={daysToFollowUpValidationStatus}
                  >
                    <Tooltip title={this.handleToolTipMessage()}>
                      <InputNumber
                        disabled={reminderDateDisabled}
                        min={MINIMUM_DAYS_OF_REMINDER}
                        max={upperDayReminderLimit}
                        value={formattedDaysToFollowUp}
                        onChange={e => this.checkIfDayNumberIsValid(e)}
                        onKeyDown={e => {
                          if (e.key === '.' || e.key === '-') {
                            e.preventDefault();
                          }
                        }}
                        onBlur={e => this.handleReminderDateOnBlur(e)}
                      />
                    </Tooltip>
                  </FormItem>
                  <ModifyDaysToFollowUpButton
                    disabled={addDisabled}
                    onClick={this.handleButtonNumberChange}
                    operator="add"
                  />
                  <span>days before the due date</span>
                </div>
                <span
                  className={`reminder-date-message ${
                    daysToFollowUpValidationStatus === 'error'
                      ? 'reminder-date-message-error'
                      : undefined
                  }`}
                >
                  {this.renderReminderDate()}
                </span>
              </div>
            </Col>
          </Row>

          <Divider />
          <FormItem {...layouts.description} label="Description" colon={false}>
            <TextArea
              rows={5}
              maxLength={2500}
              value={htmlUnescape(description)}
              autoFocus
              onChange={this.handleChangeDescription}
              required
              disabled={!editable}
            />
          </FormItem>
        </Form>
      </StyledModal>
    );
  }
}

const mapState = (state, props) => {
  const agencyId = getAgencyId(state);
  const hierarchyKey = get(state, 'session.currentUser.hierarchyKey');
  const organizationalUnitId = get(
    state,
    'session.currentUser.organizationalUnitId'
  );
  return {
    agencyId,
    hierarchyKey,
    organizationalUnitId,
  };
};

export default connect(mapState)(AddTaskModal);
