import React, { Component, Fragment } from 'react';
import { DatePicker, TimePicker } from 'antd';
import { get } from 'lodash';
import moment from 'moment-timezone';

import ReviewerField from '../reviewer-field';
import parseDate, {
  momentWithTZ,
  BENCHMARK_TIME_FORMAT,
  BENCHMARK_DATE_FORMAT,
  BENCHMARK_DATE_TIMEZ_FORMAT,
} from '../../../utils/parse-date';
import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import isMobileDevice from '../../../utils/isMobileDevice';
import StyledInput from './input-date.styled';
import classNames from 'classnames';
import {
  CONTRIBUTOR_ASSIGNMENT_DATE,
  CONTRIBUTOR_SECTION_COMPLETE_DATE,
} from '../../../constants/contributeToReport.js';
import emitter, { EventTypes } from '../../../utils/eventEmitter.js';
import { manualAutoSave } from '../../utils/auto-save.js';

const DATE_FORMAT = 'YYYY-MM-DD';
const DATETIME_FORMAT = `${DATE_FORMAT}THH:mm`;

const isMobile = isMobileDevice();

const setCurrentDate = (props, format, timezone) => {
  const { onChange, value } = props;
  const today = parseDate(value, timezone, format);
  if (!value) {
    onChange(today);
  }
};

const shouldUseTimezoneOffset = (options, defaultValue = true) =>
  get(options, 'useTimezoneOffset', defaultValue);

const onDateChange = (props, value) => {
  const { onChange, options, timezone } = props;
  const useTimezoneOffset = shouldUseTimezoneOffset(options);
  // value is a string, need to call .tz to make sure
  // timezone is applied
  const momentifiedValue = useTimezoneOffset
    ? moment.tz(value, timezone)
    : momentWithTZ(value, timezone, null, false);
  onChange && onChange(momentifiedValue);
};

class WrappedDatePicker extends Component {
  shouldComponentUpdate(nextProps) {
    return propsHasChanged(nextProps, this.props);
  }

  onChange = event => {
    const value = event.currentTarget.value;
    onDateChange(this.props, value);
  };

  render() {
    const {
      enums,
      options,
      isReviewer,
      isDraft,
      dataKey,
      parentKey,
      parentIndex,
      timezone,
      calculatedValue,
      field_type,
      ...props
    } = this.props;
    const evaluateValue = calculatedValue || this.props.value;
    const useTimezoneOffset = shouldUseTimezoneOffset(options);
    const momentifiedValue = momentWithTZ(
      evaluateValue,
      timezone,
      null,
      useTimezoneOffset
    );
    let overrideEditRights = false;
    let overrideReviewer = isReviewer;
    if (this.props.isContributeReport) {
      if (this.props.contributorAssignmentCanEdit) {
        overrideEditRights = true;
      } else if (this.props.contributorAssignmentCanView) {
        overrideReviewer = true;
      } else {
        <ReviewerField />;
      }
    }
    if (overrideReviewer && !overrideEditRights) {
      const todayMoment = moment().tz(timezone);

      let formattedValue = evaluateValue
        ? momentifiedValue.format(BENCHMARK_DATE_FORMAT)
        : ' ';

      if (formattedValue && this.props.title.split(' ').includes('Age')) {
        formattedValue = todayMoment.diff(formattedValue, 'years');
        if (formattedValue < 0) {
          formattedValue = 'N/A';
        }
      }

      return (
        <div className="reviewer-field text-field">{formattedValue}&nbsp;</div>
      );
    }

    return isMobile ? (
      <StyledInput
        type="date"
        {...props}
        onChange={this.onChange}
        value={momentifiedValue.format(DATE_FORMAT)}
      />
    ) : (
      <DatePicker
        style={{ width: '100%' }}
        size="default"
        {...props}
        format={BENCHMARK_DATE_FORMAT}
        ref={ref => (this.input = ref)}
        value={evaluateValue ? momentifiedValue : null}
        onOk={() => setCurrentDate(this.props, BENCHMARK_DATE_FORMAT, timezone)}
      />
    );
  }
}

class DateTimePicker extends Component {
  ctrDateTimeUpdate_bound = this.ctrDateTimeUpdate.bind(this);
  constructor(props) {
    super();
    this.state = {
      reportingKey: props.reportingKey,
      key: props.id,
    };
  }

  shouldComponentUpdate(nextProps) {
    return propsHasChanged(nextProps, this.props);
  }

  componentDidMount() {
    emitter.on(
      EventTypes.CTR_SECTION_TIMESTAMP_UPDATE,
      this.ctrDateTimeUpdate_bound
    );
  }

  componentWillUnmount() {
    emitter.off(
      EventTypes.CTR_SECTION_TIMESTAMP_UPDATE,
      this.ctrDateTimeUpdate_bound
    );
  }

  ctrDateTimeUpdate({ detail }) {
    if (this.props.id === detail) {
      const {
        options,
        timezone,
        data: {
          meta: { id, agencyId },
        },
      } = this.props;

      const today = parseDate(new Date(), null, BENCHMARK_DATE_TIMEZ_FORMAT);
      const useTimezoneOffset = shouldUseTimezoneOffset(options);
      const momentifiedValue = useTimezoneOffset
        ? moment.tz(today, timezone).toISOString()
        : momentWithTZ(today, timezone, null, false).toISOString();

      this.onChange({ currentTarget: { value: today } });
      manualAutoSave(id, agencyId, { [detail]: momentifiedValue });
    }
  }

  onChange = event => {
    const value = event.currentTarget.value;
    onDateChange(this.props, value);
  };

  render() {
    const {
      enums,
      options,
      isReviewer,
      isDraft,
      dataKey,
      parentKey,
      parentIndex,
      timezone,
      calculatedValue,
      field_type,
      ...props
    } = this.props;
    const useTimezoneOffset = shouldUseTimezoneOffset(options);
    const evaluateValue = calculatedValue || this.props.value;
    const momentifiedValue = momentWithTZ(
      evaluateValue,
      timezone,
      null,
      useTimezoneOffset
    );
    let overrideReviewer = isReviewer;
    let overrideEditRights = false;
    const isContributorAssignmentDate =
      this.state.reportingKey === CONTRIBUTOR_ASSIGNMENT_DATE;
    const isContributorSectionCompleteDate =
      this.state.reportingKey === CONTRIBUTOR_SECTION_COMPLETE_DATE;
    const contributorAssignmentClass = classNames({
      'assigned-date': isContributorAssignmentDate,
    });
    // Performance review assigned date should never be editable
    if (isContributorAssignmentDate || isContributorSectionCompleteDate) {
      overrideReviewer = true;
    } else if (this.props.isContributeReport) {
      if (this.props.contributorAssignmentCanEdit) {
        overrideEditRights = true;
      } else if (this.props.contributorAssignmentCanView) {
        overrideReviewer = true;
      } else {
        <ReviewerField></ReviewerField>;
      }
    }
    if (overrideReviewer && !overrideEditRights) {
      let formattedValue = evaluateValue
        ? momentifiedValue.format(BENCHMARK_DATE_TIMEZ_FORMAT)
        : undefined; // TODO: this might render undefined
      if (isContributorAssignmentDate && formattedValue) {
        if (!this.props.contributorAssignmentIsAssigned) {
          return <Fragment />;
        }
        return (
          <div
            style={{
              color: '#ddd',
              lineHeight: '18px',
              width: '175px',
              whiteSpace: 'nowrap',
              textAlign: 'right',
              marginRight: '12px',
            }}
            className="assigned-date"
          >
            <div style={{ fontWeight: 'bold', fontSize: '14px' }}>Assigned</div>
            <div>{formattedValue}</div>
          </div>
        );
      }
      if (isContributorSectionCompleteDate) {
        if (!this.props.contributorAssignmentIsComplete) {
          return <Fragment />;
        }
        return (
          <div
            style={{
              color: '#ddd',
              lineHeight: '18px',
              width: '175px',
              whiteSpace: 'nowrap',
              textAlign: 'right',
              marginRight: '12px',
            }}
            className="completed-date"
          >
            <div style={{ fontWeight: 'bold', fontSize: '14px' }}>
              Completed
            </div>
            <div>{formattedValue}</div>
          </div>
        );
      }
      return <ReviewerField value={formattedValue} />;
    }

    return isMobile ? (
      <StyledInput
        type="datetime-local"
        {...props}
        onChange={this.onChange}
        value={momentifiedValue.format(DATETIME_FORMAT)}
      />
    ) : (
      <DatePicker
        size="default"
        className={contributorAssignmentClass}
        showTime
        style={{ width: '100%', minWidth: '180px' }}
        format={BENCHMARK_DATE_TIMEZ_FORMAT}
        {...props}
        ref={ref => (this.input = ref)}
        value={evaluateValue ? momentifiedValue : null}
        allowClear={false}
        onOk={() =>
          setCurrentDate(this.props, BENCHMARK_DATE_TIMEZ_FORMAT, timezone)
        }
      />
    );
  }
}

class TimePickerInput extends Component {
  shouldComponentUpdate(nextProps) {
    return propsHasChanged(nextProps, this.props);
  }

  render() {
    const {
      enums,
      options,
      isReviewer,
      isDraft,
      dataKey,
      parentKey,
      parentIndex,
      timezone,
      value,
      calculatedValue,
      field_type,
      ...props
    } = this.props;
    const useTimezoneOffset = shouldUseTimezoneOffset(options, false);
    const momentifiedValue = momentWithTZ(
      isReviewer ? calculatedValue || value : value,
      timezone,
      null,
      useTimezoneOffset
    );
    let overrideReviewer = isReviewer;
    let overrideEditRights = false;
    if (this.props.isContributeReport) {
      if (this.props.contributorAssignmentCanEdit) {
        overrideEditRights = true;
      } else if (this.props.contributorAssignmentCanView) {
        overrideReviewer = true;
      } else {
        <ReviewerField></ReviewerField>;
      }
    }
    if (overrideReviewer && !overrideEditRights) {
      return (
        <ReviewerField
          value={
            calculatedValue || value
              ? momentifiedValue.format(BENCHMARK_TIME_FORMAT)
              : ''
          }
        />
      );
    }

    return (
      <TimePicker
        size="default"
        style={{ width: '100%' }}
        {...props}
        format={BENCHMARK_TIME_FORMAT}
        ref={ref => (this.input = ref)}
        value={value ? momentifiedValue : null}
        allowClear={false}
        onOk={() => setCurrentDate(this.props, BENCHMARK_TIME_FORMAT, timezone)}
      />
    );
  }
}

export default WrappedDatePicker;
export const DateTime = DateTimePicker;
export const TimePickerField = TimePickerInput;
