import { has, isArray } from 'lodash';
import { escapeInput } from '../../utils/request';
import { updateAutoSaveDraft } from '../../api/reports';
import postDraftReport from 'APP_ROOT/actions/post-draft-report';
import syncFormValueChanged from 'APP_ROOT/actions/sync-form-value-changed-boolean';

const autoSave = async (id, agencyId, data, dispatch) => {
  try {
    dispatch(syncFormValueChanged(false));
    await updateAutoSaveDraft(id, agencyId, data);
  } catch (e) {
    console.warn('Sorry something bad happen!'); // eslint-disable-line
  }
};

export async function manualAutoSave(id, agencyId, data) {
  try {
    await updateAutoSaveDraft(id, agencyId, data);
  } catch (e) {
    console.warn('Sorry something bad happen!'); // eslint-disable-line
  }
}

export const formatData = changedFields => {
  const data = { ...changedFields };
  return Object.keys(data).reduce((cleanedData, key) => {
    if (isArray(data[key])) {
      cleanedData[key] = data[key]
        .filter(key => key !== null)
        .map(value => {
          if (typeof value === 'object') return formatData(value);
          return value;
        });
    } else if (typeof data[key] === 'object') {
      cleanedData[key] = has(data[key], '_d')
        ? Object.values(data).join()
        : data[key];
      if (has(data[key], '_isAMomentObject'))
        cleanedData[key] = data[key]['_d'];
    } else {
      cleanedData[key] = data[key];
    }
    return cleanedData;
  }, {});
};

const saveDraft = (dispatch, selectedForm, cb = () => {}) => {
  const { data: fields } = selectedForm;
  const data = formatData(fields);
  dispatch(postDraftReport(data, cb));
};

const autoSaveDraft = (meta, changedFields, dispatch) => {
  const { id, agencyId } = meta;
  const data = formatData(changedFields);
  const escapedData = escapeInput(data);
  const isValueNull = Object.values(escapedData).find(value => value === null);
  if (typeof isValueNull === 'undefined')
    autoSave(id, agencyId, data, dispatch);
};

export const autoSaveDraftReport = (
  isDraftSaved,
  selectedForm,
  changedFields,
  isDraftJustSaved,
  dispatch,
  canRetry = false
) => {
  const { meta } = selectedForm;
  const isDraftCreated = has(meta, 'id');
  // canRetry: allow autosave continue working until the form is created. (safe retry)
  if ((!isDraftSaved && !isDraftJustSaved) || canRetry)
    saveDraft(dispatch, selectedForm);
  if (isDraftCreated) autoSaveDraft(meta, changedFields, dispatch);
};
