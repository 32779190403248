import { PERMISSIONS } from './admin';
import visibleOnlyForSandbox from './visible-only-for-sandbox';
import { adminPermissionsForSideBarOption } from './administratorPermission';

const home = {
  label: 'Home',
  anticon: 'home',
  pathname: 'dashboard',
  relatedRoutes: [
    'everybody',
    'activity',
    'performanceEvaluation',
    'complaints',
    'complaintDetails',
    'exceptionalPerformance',
    'needingReview',
    'underCare',
    'home',
    'dashboard',
  ],
  visibleFor: ['everybody'],
};

/* Should not be visible by people with listUsersByHK permissions. */
const people = {
  label: 'People',
  anticon: 'team',
  pathname: 'people',
  relatedRoutes: [
    'people',
    'officerProfile',
    'officerUseOfForce',
    'officerInternalAffairs',
    'officerActivity',
    'officerTraining',
    'officerCommunityEngagement',
    'officerPerformanceEvaluation',
  ],
  visibleFor: [
    PERMISSIONS.configureDataImport,
    PERMISSIONS.viewAllUserProfiles,
  ],
  hiddenFor: [PERMISSIONS.listUsersByHK],
};

const peopleOu = {
  label: 'People',
  anticon: 'team',
  pathname: 'peopleOu',
  relatedRoutes: [
    'peopleOu',
    'officerProfile',
    'officerUseOfForce',
    'officerInternalAffairs',
    'officerActivity',
    'officerTraining',
    'officerCommunityEngagement',
    'officerPerformanceEvaluation',
  ],
  visibleFor: [PERMISSIONS.listUsersByHK],
};

const myProfile = sidebarHrefObject => ({
  label: 'My Profile',
  anticon: 'user',
  href: sidebarHrefObject.myUserProfileHref,
  relatedRoutes: ['userProfile'],
  visibleFor: ['everybody'], // PERMISSIONS.viewOwnUserProfile
});

const organizations = {
  label: 'Organizations',
  anticon: 'apartment',
  pathname: 'OuList',
  relatedRoutes: ['OuList'],
  visibleFor: [PERMISSIONS.listOrganizationsByHK],
};

const analytics = sidebarHrefObject => ({
  label: 'Analytics',
  anticon: 'dot-chart',
  href: sidebarHrefObject.analyticsHref,
  useBrowserLocation: true,
  relatedRoutes: [],
  visibleFor: [PERMISSIONS.accessStatsModule],
});

const myOrganization = sidebarHrefObject => ({
  label: 'My Organization',
  anticon: 'bank',
  href: sidebarHrefObject.myOrganizationHref,
  relatedRoutes: ['organizationProfile'],
  visibleFor: [PERMISSIONS.listOrganizationsByHK],
});

const community = {
  label: 'Community',
  anticon: 'contacts',
  pathname: 'community',
  relatedRoutes: ['community'],
  visibleFor: [
    PERMISSIONS.manageCommunityMember,
    PERMISSIONS.viewCommunityMember,
  ],
};

const reports = ({ reportOnLoading }) => ({
  label: 'Reports',
  anticon: 'profile',
  pathname: reportOnLoading.pathname,
  relatedRoutes: ['reports', 'report'],
  isLoading: reportOnLoading.isLoading,
  iconType: 'loading',
  visibleFor: [
    'viewForms',
    PERMISSIONS.reviewForm,
    PERMISSIONS.submitUOFReport,
    PERMISSIONS.submitVPReport,
    PERMISSIONS.submitDORReport,
    PERMISSIONS.submitIACReport,
    PERMISSIONS.submitIAIReport,
    PERMISSIONS.reviewUOFReport,
    PERMISSIONS.viewAllUOFReports,
    PERMISSIONS.reviewVPReport,
    PERMISSIONS.viewAllVPReports,
    PERMISSIONS.reviewDORReport,
    PERMISSIONS.viewAllDailyObservationReports,
    PERMISSIONS.viewOwnDailyObservationReports,
    PERMISSIONS.reviewIACReport,
    PERMISSIONS.reviewIAIReport,
    PERMISSIONS.viewAllInvestigations,
    PERMISSIONS.viewAllIntakes,
    PERMISSIONS.submitReport,
    PERMISSIONS.reviewReport,
    PERMISSIONS.viewReports,
  ],
});

const files = {
  label: 'Case Files',
  anticon: 'file-search',
  pathname: 'caseFileList',
  relatedRoutes: [],
  visibleFor: [
    PERMISSIONS.manageAllCasefiles,
    PERMISSIONS.manageMyCasefiles,
    PERMISSIONS.accessCasefiles,
  ],
};

const training = sidebarHrefObject => ({
  label: 'Training',
  anticon: 'desktop',
  href: sidebarHrefObject.traningHref(''),
  useBrowserLocation: true,
  relatedRoutes: ['training', 'fieldTraining'],
  visibleFor: [PERMISSIONS.accessTraining],
});

const internalAffairs = sidebarHrefObject => ({
  label: 'Internal Affairs',
  anticon: 'solution',
  href: sidebarHrefObject.internalAffairsHref,
  useBrowserLocation: true,
  relatedRoutes: [],
  visibleFor: visibleOnlyForSandbox(
    ['viewIACaseLoad', 'manageIACaseLoad'],
    sidebarHrefObject.session
  ),
});

const admin = {
  label: 'Admin',
  anticon: 'key',
  pathname: 'administrator',
  relatedRoutes: [
    'administrator',
    'administratorAgencies',
    'administratorAgency',
    'administratorAgencyUser',
    'administratorAgencyUsers',
    'administratorAgencyTemplate',
    'administratorAgencyTemplates',
    'administratorAgencyWorkflow',
    'administratorAgencyWorkflows',
    'administratorAgencyReport',
    'administratorAgencyReports',
    'administratorAgencyAuditLogs',
    'administratorAgencyViewAuditLogs',
    'administratorAgencyMigratedFiles',
    'administratorAgencyBulkUsersImport',
    'administratorAgencyLiveEventCheckIn',
    'administratorAgencyCasefiles',
    'administratorAgencyCasefileAuditLogs',
  ],
  visibleFor: adminPermissionsForSideBarOption,
};

const settings = sidebarHrefObject => ({
  label: 'Settings',
  icon: 'bdm-icon-settings',
  pathname: 'settings',
  relatedRoutes: ['settings'],
  // visibleFor: sidebarHrefObject.isSSO ? ['nobody'] : ['everybody'],
  visibleFor: ['nobody'],
});

const getReportOnLoadingOptions = supportedAgency => {
  return {
    //PPL-871
    //Because of the way that the sidebar buttons menu are build is not possible to disable the report button,
    //so insted an empty path is send and the icon will appers as loading until the workflow agency is confirmed.
    pathname: supportedAgency.agencyWorkFlowInformationLoaded ? 'reports' : '',
    isLoading: !supportedAgency.agencyWorkFlowInformationLoaded,
  };
};

export const getSidebarHrefObject = (session, otherHref, supportedAgency) => {
  const {
    currentUser: {
      userId = '',
      agencyId = '',
      isSSO = false,
      organizationalUnitId = '1',
    } = {},
  } = session;

  return {
    myUserProfileHref: `/agency/${agencyId}/user-profile/${userId}`,
    myOrganizationHref: `/organization-profile/${organizationalUnitId}`,
    analyticsHref:
      otherHref.STATS_WEBSITE_URL + `#access_token=${otherHref.token}`,
    internalAffairsHref: `${otherHref.STATS_WEBSITE_URL}/internal_affairs#access_token=${otherHref.token}`,
    traningHref: otherHref.getLmsUrl,
    isSSO,
    session,
    reportOnLoading: getReportOnLoadingOptions(supportedAgency),
  };
};

export const getSideBarOptions = sidebarHrefObject => {
  return [
    home,
    people,
    peopleOu,
    myProfile(sidebarHrefObject),
    organizations,
    analytics(sidebarHrefObject),
    myOrganization(sidebarHrefObject),
    community,
    reports(sidebarHrefObject),
    files,
    training(sidebarHrefObject),
    internalAffairs(sidebarHrefObject),
    admin,
    settings(sidebarHrefObject),
  ];
};
